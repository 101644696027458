import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StyledImage from '../components/StyledImage';
import logo from '../public/img/logo.png';
import { useRouter } from 'next/router';
import { DEFAULT_LANG, getLangCountry } from '../helpers/languages';
import { DEFAULT_COUNTRY } from '../helpers/countries';
import { LangCountry } from '../models/app';
import i18next from 'i18next';
import moment from 'moment';
import Link from 'next/link';

const getLangCountryFromUrl = (url: string | undefined): LangCountry => {
  if (url?.startsWith('/')) {
    const paths = url.split('/');
    if (paths.length > 1 && paths[1].includes('_')) {
      return getLangCountry({ lang: paths[1] });
    }
  }
  return {
    lang: DEFAULT_LANG,
    country: DEFAULT_COUNTRY
  };
};

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { lang } = getLangCountryFromUrl(router.asPath);

  useEffect(() => {
    i18next.changeLanguage(lang);
    moment.locale(lang);
  }, [lang]);

  return (
    <div>
      <header className="relative bg-white">
        <nav
          aria-label="Top"
          className="px-4 sm:px-6 lg:px-12 border-b border-gray-200"
        >
          <div>
            <div
              id="header__container"
              className="h-16 flex items-center justify-between"
            >
              <div className="lg:flex-1 lg:flex lg:items-center">
                <Link href="/">
                  <StyledImage
                    src={logo}
                    className="w-20 cursor-pointer"
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <main className="flex flex-col justify-center items-center w-full mx-auto px-4 py-64 sm:px-6 lg:px-8 bg-error bg-no-repeat bg-cover">
        <h1
          className="text-8xl text-white text-center mb-4 text-shadow uppercase"
          dangerouslySetInnerHTML={{ __html: t('404_TEXT') }}
        />
        <div className="md:w-96">
          <Link
            href="/"
            className="block w-full text-center bg-primary text-black px-3 py-4 rounded-md cursor-pointer"
          >
            {t('BACK_TO_HOME')}
          </Link>
        </div>
      </main>
      <footer className="bg-black" aria-labelledby="footer-heading">
        <div className="mx-auto py-8 px-4 sm:px-6 lg:px-12">
          <div className="md:flex md:items-center md:justify-between">
            <p className="mt-8 text-sm text-gray-400 md:mt-0 md:order-1">
              &copy; {new Date().getFullYear()} Trerè lnnovation s.r.l.
              Unipersonale
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default NotFoundPage;
